import { functions } from '@wap-client/core';
import { UISectionProps } from './types';
import { useInView } from 'framer-motion';
import useDeviceType from '@/hooks/useDeviceType';
import React, { useRef } from 'react';

// const animations = {
//   container: {
//     hidden: {
//       opacity: 0,
//     },
//     show: {
//       opacity: 1,
//       transition: {
//         duration: 0.5,
//         delayChildren: 0.3,
//         staggerChildren: 0.15,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
// };

const Section: React.FC<UISectionProps> = ({
  className,
  children,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 30,
  mtMobile = 30,
}) => {
  if (!children) return null;

  const deviceType = useDeviceType();
  const ref = useRef<HTMLElement>(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      className={functions.classnames('custom-section', className)}
      // variants={animations.container}
      // initial="hidden"
      // animate={`${isInView ? 'show' : 'hidden'}`}
      // viewport={{ once: true, amount: 0.1 }}
      ref={ref}
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      {children}
    </section>
  );
};

export default Section;
